.placeholderModuleContainer {
  height: 120px;
  background: lightgrey;
  padding: 4px;
}

.placeholderModuleContainer div {
  height: 100%;
  background: lightgrey;
  border: dashed grey 0.2em;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.placeholderModuleLabel {
  color: grey;
  font-size: 2em;
}
