.imagePreviewRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.imagePreviewColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}