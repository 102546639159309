.appContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.appToolbar {
  background: white;
  height: 100px;
}

.appHomepageContent {
  flex: 1;
  overflow: auto;
}

.appBottomBar {
  background: white;
  display: none;
  height: 100px;
}